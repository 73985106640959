import React, { Component } from 'react';
import { IActionsProps, IActionsState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import ActionsComponent from '@kurtosys/ksys-app-components/dist/components/base/Actions';
import Wrapper from './styledComponents/Wrapper';

@inject('appStore', 'actionsStore')
@observer
export class Actions extends Component<IActionsProps, IActionsState> {
	static configurationKey: 'actions' = 'actions';
	static styleKey: 'actions' = 'actions';
	constructor(props: IActionsProps) {
		super(props);
	}
	render() {
		const { className, actionsStore } = this.props;

		if (!actionsStore) {
			return null;
		}

		const { props } = actionsStore;

		if (!props) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<ActionsComponent { ...props } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Actions, 'actions');
