import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	token: '0059eec6-ffeb-440a-9f3b-8c1221f7b654',
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	core: {
		serviceUrl: 'https://api.fundpress.io',
		skeletonLoaders: {
			variables: [
				{
					key: 'fillColor',
					value: '#dddddd',
				},
			],
			loaders: [
				{
					id: 'ksys-app-start',
					breakpoints: [
						{
							minWidthInPixels: 0,
							style: {
								height: '86px',
							},
							children: [
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '11.90625px',
									'height': '28px',
									'width': '100%',
								},
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '59.8125px',
									'height': '18px',
									'width': '100%',
								},
							],
						},
					],
				},
			],
		},
	},
	data: {
		context: {
			entityByType: {
				CLSS: {
					inputKey: 'isin',
					clientCodeProperty: 'isin',
				},
			},
		},
	},
	components: {
		app: {
			noDataOptions: {
				hide: false,
			},
			title: {
				queryOptionsType: 'none',
				code: 'title',
			},
			commentary: {
				queryOptionsType: 'none',
				value: 'The fun begins'
			},
		},
		actions: {
			props: {
				buttons: [
					{
						redirect: {
							defaultUrl: '/{site}/{country}/{language}/{investorType}/contact/',
							options: {
								preserveOriginHash: false,
								preserveOriginPath: false,
								preserveOriginQueryString: false,
							},
						},
						text: 'CONTACT US',
					},
				],
			},
		},
	},
	assetOverrides: {
		base: {
			tooltip: {
				defaultIcon: 'InformationIcon.svg',
			},
		},
	},
	redirectInputs: [
		{
			key: 'site',
			query: {
				key: 'site',
				queryOptionsType: 'input',
				transforms: [
					{
						caseType: 'param',
						transformOptionsType: 'case',
					},
				],
			},
		},
		{
			key: 'country',
			query: {
				key: 'country',
				queryOptionsType: 'input',
				transforms: [
					{
						caseType: 'param',
						transformOptionsType: 'case',
					},
				],
			},
		},
		{
			key: 'language',
			query: {
				key: 'language',
				queryOptionsType: 'input',
				transforms: [
					{
						caseType: 'param',
						transformOptionsType: 'case',
					},
				],
			},
		},
		{
			key: 'investorType',
			query: {
				key: 'investorType',
				queryOptionsType: 'input',
				transforms: [
					{
						caseType: 'param',
						transformOptionsType: 'case',
					},
				],
			},
		},
	],
};
