import { utils, query, helpers } from '@kurtosys/ksys-app-template';
import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { ICardProps, ICardTooltip } from '@kurtosys/ksys-app-components/dist/components/base/Card/models';
import { ITextProps, ITextTooltip } from '@kurtosys/ksys-app-components/dist/components/base/Text/models';
import { IPlaceholderFormat } from '../models/IPlaceholderFormat';
import { Manifest } from '../../../configuration/Manifest';
import { IConfigurationRedirectInput } from '../../../models/app/IConfigurationRedirectInput';
/* [Component: appStoreComponentImport] */

export class AppStore extends AppStoreBase {
	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
	}

	@computed
	get hasData(): boolean {
		let hasData = this.isBootstrapped && !utils.typeChecks.isNullOrEmpty(this.commentary);
		if (this.appComponentConfiguration) {
			const { noDataOptions } = this.appComponentConfiguration;
			if (noDataOptions) {
				const { customValidation } = noDataOptions;
				if (customValidation) {
					const { queryStore } = this.storeContext;
					const { includeDefaultValidation, validateWithDefaultAs, conditionalValidation } = customValidation;
					const conditionalHelper = new helpers.ConditionalHelper(conditionalValidation);
					const isValid = conditionalHelper.matchesWithOptions({ executionOptions: queryStore.executionOptions });
					if (includeDefaultValidation) {
						switch (validateWithDefaultAs) {
							case 'ANY':
								hasData = hasData || isValid;
								break;
							case 'BOTH':
							default:
								hasData = hasData && isValid;
								break;
						}
					}
					else {

						hasData = isValid;
					}
				}
			}
		}
		return hasData;
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get title(): string | undefined {
		const title = this.getQueryValue(this.appComponentConfiguration && this.appComponentConfiguration.title);
		return this.applyPlaceholderFormatting(title, this.titleFormating);
	}

	@computed
	get titleTooltip(): ICardTooltip | undefined {
		const tooltipQueries = this.appComponentConfiguration && this.appComponentConfiguration.titleTooltip;
		const tooltipProps = query.Query.convertQueryMime(tooltipQueries, this.storeContext.queryStore.executionOptions);
		return tooltipProps && { target: 'title', ...tooltipProps };
	}

	@computed
	get titleFormating(): IPlaceholderFormat | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.titleFormating;
	}

	@computed
	get subtitle(): string | undefined {
		const subtitle = this.getQueryValue(this.appComponentConfiguration && this.appComponentConfiguration.subtitle);
		return this.applyPlaceholderFormatting(subtitle, this.subtitleFormating);
	}

	@computed
	get subTitleTooltip(): ICardTooltip | undefined {
		const tooltipQueries = this.appComponentConfiguration && this.appComponentConfiguration.subTitleTooltip;
		const tooltipProps = query.Query.convertQueryMime(tooltipQueries, this.storeContext.queryStore.executionOptions);
		return tooltipProps && { target: 'subTitle', ...tooltipProps };
	}

	@computed
	get subtitleFormating(): IPlaceholderFormat | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.subtitleFormating;
	}

	@computed
	get redirectInputsConfiguration(): IConfigurationRedirectInput[] | undefined {
		const { appStore } = this.storeContext;
		return appStore.configuration && appStore.configuration.redirectInputs;
	}

	applyPlaceholderFormatting = (value: any, placeholder?: IPlaceholderFormat) => {
		if (placeholder) {
			const { translationStore } = this.storeContext;
			const { translate } = translationStore;
			const { format, placeholderKey } = placeholder;
			if (format && placeholder && translate) {
				return translate(format, { [placeholderKey]: value });
			}
		}
		return value;
	}

	@computed
	get commentary(): string | undefined {
		return this.getQueryValue(this.appComponentConfiguration && this.appComponentConfiguration.commentary);
	}

	@computed
	get commentaryTooltip(): ITextTooltip | undefined {
		const tooltipQueries = this.appComponentConfiguration && this.appComponentConfiguration.commentaryTooltip;
		return query.Query.convertQueryMime(tooltipQueries, this.storeContext.queryStore.executionOptions);
	}

	@computed
	get cardProps(): ICardProps {
		const props = this.appComponentConfiguration && this.appComponentConfiguration.cardProps;

		const tooltips = [];
		if (this.titleTooltip) {
			tooltips.push(this.titleTooltip);
		}
		if (this.subTitleTooltip) {
			tooltips.push(this.subTitleTooltip);
		}

		const response: ICardProps = {
			tooltips,
			title: this.title,
			subtitle: this.subtitle,
		};

		return utils.object.deepMergeObjects(props || {}, response);
	}

	@computed
	get textProps(): ITextProps {
		const props = this.appComponentConfiguration && this.appComponentConfiguration.textProps;
		const response: ITextProps = {
			value: this.commentary || '',
			tooltip: this.commentaryTooltip,
		};
		return utils.object.deepMergeObjects(props || {}, response);
	}

}