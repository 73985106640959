import { query, utils } from '@kurtosys/ksys-app-template';
import { StoreContext } from '../configuration/StoreContext';
import { Manifest } from '../configuration/Manifest';
import { observable } from 'mobx';
import { IConfigurationComponentOrchestration } from '../models/app/IConfigurationComponentOrchestration';
import { IExecutionOptions, IQueryOptions } from '../models/commonTypes';

export abstract class StoreBase {
	storeContext: StoreContext;
	manifest: Manifest;
	@observable isInitialized: boolean = false;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
		this.manifest = new Manifest();
	}
	abstract async initialize(): Promise<void>;

	getQueryValue(queryOptions?: IQueryOptions, responseForContext?: object, overrideExecutionOptions?: Partial<IExecutionOptions>) {
		if (queryOptions) {
			const { queryStore } = this.storeContext;
			const responseContextExecutionOptions: Partial<IExecutionOptions> = {
				responseContext: responseForContext,
			};
			overrideExecutionOptions = utils.object.deepMergeObjectsWithOptions(
				{ arrayMergeStrategy: 'DeepMerge' },
				responseContextExecutionOptions,
				overrideExecutionOptions || {},
			);
			return queryStore.query(queryOptions, overrideExecutionOptions);
		}
		return;
	}

	mergeQueriesAndProps<T>(componentOrchestration?: IConfigurationComponentOrchestration): T | undefined {
		if (componentOrchestration) {
			const { queryStore, translationStore } = this.storeContext;
			const { props, queries } = componentOrchestration;
			const translationHelper = translationStore && translationStore.translationHelper;
			const queryResults = queries ? query.Query.convertQueryMime(queries, queryStore.executionOptions, { translationHelper }) : {};
			const response: T = utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, props || {}, queryResults);
			return response;
		}
	}

	convertQueryMime(queries: any) {
		const { queryStore, translationStore } = this.storeContext;
		const translationHelper = translationStore && translationStore.translationHelper;
		return queries ? query.Query.convertQueryMime(queries, queryStore.executionOptions, { translationHelper }) : {};
	}
}