import { query } from '@kurtosys/ksys-app-template';
import { computed, action } from 'mobx';
import { IActionsProps } from '@kurtosys/ksys-app-components/dist/components/base/Actions/models/IActionsProps';
import { IRedirectButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/RedirectButton/models';
import { StoreBase } from '../../../common/StoreBase';
import { IActionsConfiguration } from '../models';
import { IConfigurationRedirectInput } from '../../../models/app/IConfigurationRedirectInput';
import { IRedirectConditional } from '@kurtosys/ksys-app-template/dist/models/helpers/redirect';

const Query = query.Query;

export class ActionsStore extends StoreBase {
	static componentKey: 'actions' = 'actions';

	@computed
	get configuration(): IActionsConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(ActionsStore.componentKey);
		}
	}

	@computed
	get redirectInputs(): IConfigurationRedirectInput[] | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			const { appStore } = this.storeContext;
			return appStore.redirectInputsConfiguration;
		}
	}

	@computed
	get props(): IActionsProps | undefined {
		const { queryStore } = this.storeContext;
		const actionProps = this.configuration && this.mergeQueriesAndProps(this.configuration) as IActionsProps | undefined;
		const redirectInputs = this.redirectInputs && this.redirectInputs.map(redirectInput => Query.convertQueryKeyValue(redirectInput as any, queryStore.executionOptions));
		if (actionProps && redirectInputs && redirectInputs.length > 0) {
			const { buttons } = actionProps;
			if (Array.isArray(actionProps.buttons)) {
				actionProps.buttons = buttons.map((button: IRedirectButtonProps) => {
					// Set the redirect inputs on the default url
					if (button.redirect && button.redirect.defaultUrl) {
						button.redirect.defaultUrl = this.applyRedirectInputs(button.redirect.defaultUrl, redirectInputs);
					}
					// Set the redirect inputs on the confitional urls
					if (button.redirect && button.redirect.conditionalRedirects) {
						button.redirect.conditionalRedirects.forEach((redirect: IRedirectConditional) => {
							redirect.url = this.applyRedirectInputs(redirect.url, redirectInputs);
						});
					}
					button.payload = redirectInputs.reduce((acc: { [key: string]: any }, redirectInput) => {
						acc[redirectInput.key] = redirectInput.query;
						return acc;
					}, {});
					return button;
				});
			}
		}
		return actionProps;
	}

	applyRedirectInputs(url: string, redirectInputs: { [key: string]: any }): string {
		if (redirectInputs && redirectInputs.length > 0) {
			for (let i = 0; i < redirectInputs.length; i++) {
				const redirectInput = redirectInputs[i];
				const inputKey = redirectInput.key;
				const inputValue = redirectInput.query;
				if (inputKey && inputValue) {
					const keyRegex = new RegExp(`{${ inputKey }}`, 'g');
					url = url.replace(keyRegex, inputValue);
				}
			}
		}
		return url;
	}

	@action
	async initialize(): Promise<void> {

	}
}