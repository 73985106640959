import React, { Component, Suspense, lazy, Fragment } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import Card from '@kurtosys/ksys-app-components/dist/components/base/Card';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';
import Actions from '../Actions';

@inject('appStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}
	render() {
		const { className, appStore, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (!isBootstrapped) {
			if (skeletonLoader) {
				return <SkeletonLoader config={skeletonLoader} variables={skeletonLoaders && skeletonLoaders.variables} />;
			}
			return <Loading />;
		}
		const { textProps, theme, show, showPlaceholder, noDataPlaceholder: noDataPlaceholderText, libraryComponentsConfiguration, cardProps, getTranslateFunction, assets, assetCacheOptions, assetRegisters } = appStore;

		if (!show) {
			return null;
		}

		const noDataPlaceholder = <NoDataPlaceholder>{noDataPlaceholderText}</NoDataPlaceholder>;

		const translate = getTranslateFunction();
		return (
			<Application theme={theme} configuration={libraryComponentsConfiguration} translate={translate} assets={assets} assetCacheOptions={assetCacheOptions} assetRegisters={assetRegisters}>
				<Wrapper className={className}>
					<Card {...cardProps}>
						{showPlaceholder && noDataPlaceholder}
						{!showPlaceholder && (
							<Fragment>
								<Text {...textProps} />
								<Actions />
							</Fragment>
						)}
					</Card>
				</Wrapper>
			</Application>
		);
	}
}

export default InjectedStyledComponent(App, 'app');
