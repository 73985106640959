import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {},
	theme: {
		base: {
			actions: {
				children: {
					wrapper: {
						text: {
							align: 'center',
						},
						overrides: [
							{
								selector: 'button',
								style: {
									color: '#2e91dd',
									border: {
										_value: '2px solid #2e91dd',
									},
									padding: {
										_value: '10px 15px',
									},
									font: {
										weight: 'bold',
									},
								},
							},
						],
					},
				},
			},
			button: {
				cursor: 'pointer',
			},
			card: {
				children: {
					wrapper: {
						border: {
							top: {
								_value: '6px solid #2e91dd',
							},
							left: {
								_value: '1px solid #eaeaea',
							},
							bottom: {
								_value: '6px solid #2e91dd',
							},
							right: {
								_value: '1px solid #eaeaea',
							},
						},
						background: {
							color: '#fafafa',
						},
						padding: {
							_value: '5px 25px 20px 25px',
						},
					},
					title: {
						text: {
							align: 'center',
						},
						color: '#2e91dd',
					},
					subtitle: {
						text: {
							align: 'center',
						},
						color: '#4d8cbc',
						padding: {
							bottom: '15px',
						},
						border: {
							bottom: {
								_value: `1px solid #eaeaea`,
							},
						},
					},
				},
			},
			redirectButton: {
				children: {
					wrapper: {
						display: 'inline-block',
						margin: {
							right: '5px',
						},
					},
				},
			},
			tooltip: {
				children: {
					wrapper: {
						display: 'inline-block',
						verticalAlign: 'text-bottom',
						overrides: [
							{
								selector: '[data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									position: 'absolute',
								},
							},
							{
								selector: 'div[data-placement="top"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									bottom: '-14px',
								},
							},
							{
								selector: 'div[data-placement="bottom"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									top: '-13px',
									transform: 'rotate(180deg)',
								},
							},
							{
								selector: 'div[data-placement="left"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									right: '-11px',
									transform: 'rotate(-90deg)',
								},
							},
							{
								selector: 'div[data-placement="right"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									left: '-11px',
									transform: 'rotate(90deg)',
								},
							},
						],
					},
					tooltipIconButton: {
						cursor: 'pointer',
						position: 'sticky',
					},
					tooltipCharacter: {
						font: {
							size: '9px',
						},
						min: {
							width: '1.5em',
						},
						margin: {
							_value: '0 0.5em',
						},
						padding: {
							top: '0.2em',
							bottom: '0.2em',
							left: '0.5em',
							right: '0.5em',
						},
						color: 'white',
						background: {
							color: '#1EA7FD',
						},
						border: {
							radius: '1.5em',
						},
						text: {
							align: 'center',
						},
					},
					tooltipIcon: {
						width: '16px',
						height: '16px',
					},
					tooltipContent: {
						color: 'black',
						max: {
							width: '250px',
						},
						background: {
							color: 'white',
						},
						border: {
							style: 'solid',
							width: '1px',
							color: '#cccccc',
							radius: '3px',
						},
						box: {
							shadow: '2px 2px 0 rgba(0,0,0,0.1)',
							sizing: 'border-box',
						},
						padding: {
							_value: '4px',
						},
						font: {
							size: '14px',
							weight: 'normal',
						},
						text: {
							align: 'center',
						},
						margin: {
							_value: '3px',
							bottom: '2px',
						},
					},
					clickLayer: {
						position: 'fixed',
						top: '0',
						bottom: '0',
						left: '0',
						right: '0',
						background: {
							color: 'transparent',
						},
					},
					arrowIcon: {
						fill: 'white',
						stroke: '#cccccc',
					},
				},
			},
			text: {
				children: {
					content: {
						display: 'inline-block',
						overrides: [
							{
								selector: '> *',
								style: {
									display: 'inline-block',
								},
							},
						],
					},
					toggleWrapper: {
						display: 'inline-block',
						overrides: [
							{
								selector: '[data-selector="base-button"]',
								style: {
									color: '#2e91dd',
									cursor: 'pointer',
									outline: {
										_value: 'none',
									},
									border: {
										_value: 'none',
									},
									background: {
										_value: 'none',
									},
								},
							},
						],
					},
				},
			},
		},
	},
};