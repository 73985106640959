import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { Manifest } from './Manifest';
import { ActionsStore } from '../components/Actions/stores/ActionsStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	actionsStore: ActionsStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.actionsStore = new ActionsStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			actionsStore: this.actionsStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {

	}
}